<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="about_client" service="refMortgage" backlink="/refinance-mortgage/members" />
      <div class="content illustration illustration_form">
        <div class="content__container">
          <Clients service="refMortgage" :clients="clients" nextlink="/refinance-mortgage/about" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Clients from '@/components/service/Clients.vue'

export default {
  name: 'Ref-Mortgage-client',
  props: { clients: { type: Number } },
  components: { Backlink, Clients }
}
</script>
